import axios from 'axios';

// const BASE_URL = 'http://localhost:8080/'; 
const BASE_URL = 'https://kkcmsreact.tukzolo.com/api2/'; 

export default axios.create({
	baseURL: BASE_URL,
	// headers: {
	// 	'X-Authorization': 'Anonymous'
	// }
});