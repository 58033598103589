import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Container,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  ListSubheader,
  Menu,
  Slide,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
import {
  lighten,
  makeStyles,
  createTheme,
  withStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import StoreIcon from "@material-ui/icons/Store";
import CancelIcon from "@material-ui/icons/Cancel";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import API from "../services/api";

import LoginModal from "../components/LoginModal";
import { Settings } from "luxon";
import { DateTime } from "luxon";
import NewSaleItemDialog from "../components/NewSaleItemDialog";
import CommonDialog from "../components/CommonDialog";

Settings.defaultLocale = "th";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const useEditSaleDialogStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const useDropdownStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuItemRed: {
    color: "red",
    text: {
      color: "red",
    },
  },
  root2: {
    background: "blue",
  },
}));

const useButtonStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const statusTheme = createTheme({
  palette: {
    primary: green,
    secondary: red,
  },
});

function createData(
  name,
  calories,
  customer,
  line,
  cellProvince,
  totalAmt,
  goodsIssu,
  saleStatus,
  saleStatusCode,
  shipmentStatus,
  shipmentStatusCode
) {
  return {
    name,
    calories,
    customer,
    line,
    cellProvince,
    totalAmt,
    goodsIssu,
    saleStatus,
    saleStatusCode,
    shipmentStatus,
    shipmentStatusCode,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Shipment ID",
  },
  { id: "calories", numeric: true, disablePadding: false, label: "Order Date" },
  { id: "customer", numeric: false, disablePadding: false, label: "Customer" },
  { id: "line", numeric: false, disablePadding: false, label: "Line/IB" },
  {
    id: "cellProvince",
    numeric: true,
    disablePadding: false,
    label: "Province/Zip",
  },
  { id: "totalAmt", numeric: true, disablePadding: false, label: "Amount" },
  { id: "goodsIssu", numeric: true, disablePadding: false, label: "GI" },
  {
    id: "saleStatus",
    numeric: true,
    disablePadding: false,
    label: "Sale Status",
  },
  {
    id: "shipmentStatus",
    numeric: true,
    disablePadding: false,
    label: "Shipment Status",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          List of sales
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useTableStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 20,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const useSaleItemStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: 500,
    // height: 450,
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
}));

export default function Sales() {
  const classes = useStyles();
  const [selectedFromDate, setSelectedFromDate] = React.useState(new Date());
  const [selectedToDate, setSelectedToDate] = React.useState(new Date());
  const [dataToModal, setDataToModal] = React.useState("false");
  const [dataToCommonDialog, setDataToCommonDialog] = React.useState("false");

  const [editSaleid, setEditSaleid] = React.useState(-1);
  const [openSaleEditDialog, setOpenSaleEditDialog] = React.useState(false);
  const [districts, setDistricts] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [provinces, setProvinces] = React.useState([]);
  const [openGoodIssueResult, setOpenGoodIssueResult] = React.useState(false);

  React.useEffect(() => {
    loadProvinces();
  }, []);

  const [saleEdit, setSaleEdit] = React.useState({
    shipment: {
      firstname: "",
      lastname: "",
      addr1: "",
      addr2: "",
      province: { name: "" },
      recordDateTime: 0,
    },
    sale: { remark: "" },
  });
  const [saleItems, setSaleItems] = React.useState([]);
  const [saleItemsNew, setSaleItemsNew] = React.useState([]);
  const [shouldOpenMenuSaleStatus, setShouldOpenMenuSaleStatus] =
    React.useState(null);
  const [shouldOpenMenuShipmentStatus, setShouldOpenMenuShipmentStatus] =
    React.useState(null);
  const [openConfirmUpdateDialog, setOpenConfirmUpdateDialog] =
    React.useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] =
    React.useState(false);

  const [envelopTxt, setEnvelopTxt] = React.useState("");

  const handleCloseMenuSaleStatus = () => {
    setShouldOpenMenuSaleStatus(null);
  };
  const handleMenuItemClickSaleStatus = (event, index) => {
    setShouldOpenMenuSaleStatus(null);
    console.log("handleMenuItemClickSaleStatus", event.currentTarget.value);
    setSaleEdit({
      ...saleEdit,
      sale: {
        ...saleEdit.sale,
        statusCode: event.currentTarget.value,
      },
    });
  };

  const handleCloseMenuShipmentStatus = () => {
    setShouldOpenMenuShipmentStatus(null);
  };

  const handleMenuItemClickShipmentStatus = (event, index) => {
    setShouldOpenMenuShipmentStatus(null);
    setSaleEdit({
      ...saleEdit,
      shipment: {
        ...saleEdit.shipment,
        statusCode: event.currentTarget.value,
      },
    });
  };

  const updateGoodIssue = async () => {
    console.log("in updateGoodIssue");

    try {
      const req = {};
      req.body = {
        shipmentid: saleEdit.shipment.shipmentid,
      };

      await API.post("updateGoodIssue", req, { withCredentials: true }).then(
        (response) => {
          console.log("response", response);
          setOpenGoodIssueResult(true);
        }
      );
    } catch (error) {
      console.log("error ", error);
    }
  };

  const updateSale = async () => {
    // console.log("in updateSale");
    // console.log(" saleItemsNew", saleItemsNew);

    try {
      const req = {};
      req.body = {
        shipment: saleEdit.shipment,
        sale: saleEdit.sale,
        saleItemsNew: saleItemsNew,
        saleItems: saleItems,
      };

      await API.post("updateShipment", req, { withCredentials: true }).then(
        (response) => {
          console.log("response", response);

          setOpenSaleEditDialog(false);
          setSaleItemsNew([]);

          openSuccessDialog("Success Update sale");
        }
      );
    } catch (error) {
      console.log("error ", error);
      openErrorDialog("Error Update sale");
    }
  };

  const printEnvelop = async () => {
    setEnvelopTxt("");

    if (selected.length === 0) {
      return;
    }

    try {
      const req = {};
      req.body = {
        selectedids: selected,
        shipmentStatusCode: 3001,
      };

      await API.post("printEnvelop", req, {
        withCredentials: true,
      }).then((response) => {
        console.log("response", response);
        setEnvelopTxt(response.data.body.searchTxt);
      });
    } catch (error) {
      console.log("error ", error);
      openErrorDialog("Error Update shipment status");
    }
  };

  const updateShipmentToShiped = async () => {
    if (selected.length === 0) {
      return;
    }

    try {
      const req = {};
      req.body = {
        selectedids: selected,
        shipmentStatusCode: 3001,
      };

      await API.post("updateShipmentStatus", req, {
        withCredentials: true,
      }).then((response) => {
        console.log("response", response);
        openSuccessDialog("Success Update shipment status");
        performSearch("ok2");
      });
    } catch (error) {
      console.log("error ", error);
      openErrorDialog("Error Update shipment status");
    }
  };

  const createNewSale = async () => {
    console.log("in createNewSale");

    try {
      const req = {};
      req.body = {
        shipment: saleEdit.shipment,
        sale: saleEdit.sale,
        saleItemsNew: saleItemsNew,
      };

      await API.post("createNewSale", req, { withCredentials: true }).then(
        (response) => {
          console.log("response", response);
          openSuccessDialog("Success Create new sale");
          // setDataToCommonDialog('success ' + new Date());

          //setOpenSaleEditDialog(false);
          //setSaleItemsNew([]);
        }
      );
    } catch (error) {
      console.log("error ", error);
      openErrorDialog("Error Create new sale");
      // setDataToCommonDialog('error ' + new Date());
    }
  };

  // document.onkeydown = function (evt) {
  //   if (evt.keyCode === 27) {
  //     setOpenSaleEditDialog(false);
  //   }
  // };

  const loadSale = async (saleid) => {
    console.log("in loadSale", editSaleid);
    console.log("in loadSale saleid", saleid);

    try {
      const req = {};
      req.body = {
        shipmentid: saleid,
      };

      await API.post("getShipment", req, { withCredentials: true }).then(
        (response) => {
          console.log("response", response);
          console.log("response.data", response.data.body.shipment.firstname);
          setSaleEdit({
            ...saleEdit,
            shipment: response.data.body.shipment,
            sale: response.data.body.sale,
          });
          setSaleItems(response.data.body.list);

          setOpenSaleEditDialog(true);
        }
      );
    } catch (error) {
      console.log("error ", error);
    }
  };

  const newSale = async (shipmentid) => {
    console.log("in newSale", shipmentid);

    try {
      const req = {};
      req.body = {
        shipmentid: shipmentid,
      };

      await API.post("newSale", req, { withCredentials: true }).then(
        (response) => {
          console.log("response", response);

          setSaleEdit({
            ...saleEdit,
            shipment: response.data.body.shipment,
            sale: response.data.body.sale,
          });
          setSaleItems([]);
          setSaleItemsNew([]);
          setOpenSaleEditDialog(true);

          // setSaleEditFirstname(response.data.body.shipment.firstname);
          // setSaleEdit({
          //   ...saleEdit,
          //   shipment: response.data.body.shipment,
          //   sale: response.data.body.sale,
          // });
          // setSaleItems(response.data.body.list);

          // setOpenSaleEditDialog(true);
        }
      );
    } catch (error) {
      console.log("error ", error);
    }
  };

  const handleFromDateChange = (date) => {
    setSelectedFromDate(date);
  };
  const handleToDateChange = (date) => {
    setSelectedToDate(date);
  };
  const dropDownClasses = useDropdownStyles();
  const dropDownFocusClass = (event) => {
    console.log("dropDownFocusClass", event.target.value);
  };
  const [saleStatusCode, setSaleStatusCode] = React.useState(-1);
  const [shipmentStatusCode, setShipmentStatusCode] = React.useState(-1);
  const [customer, setCustomer] = React.useState("");
  const handleSaleStatusChange = (event) => {
    setSaleStatusCode(event.target.value);
  };
  const buttonClasses = useButtonStyles();
  const editSaleDialogClasses = useEditSaleDialogStyles();

  const tableClasses = useTableStyles();
  const saleItemClasses = useSaleItemStyles();

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const rows0 = [];

  const rows1 = [
    createData("New Cupcake", 305, 3.7, 67, 4.3),
    createData("New Donut", 452, 25.0, 51, 4.9),
  ];

  const rows2 = [
    createData("Cupcake", 305, 3.7, 67, 4.3),
    createData("Donut", 452, 25.0, 51, 4.9),
    createData("Eclair", 262, 16.0, 24, 6.0),
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  ];

  const [rows, setRows] = React.useState(rows0);

  const loadDistricts = async (param) => {
    console.log("in loadDistricts", param);

    try {
      const req = {};
      req.body = {
        shipment: { zipCode: saleEdit.shipment.zipCode },
      };
      await API.post("loadDistricts", req, { withCredentials: true }).then(
        (response) => {
          console.log("response", response);

          if (response.data.body.list) {
            console.log(
              "response.data.body.list length",
              response.data.body.list.length
            );

            if (response.data.body.list.length > 0) {
              setSaleEdit({
                ...saleEdit,
                shipment: {
                  ...saleEdit.shipment,
                  district: response.data.body.list[0],
                },
              });
              setDistricts(response.data.body.list);
            }
          } else {
          }
        }
      );
    } catch (error) {
      console.log("error ", error);
    }
  };

  const loadCities = async (param) => {
    console.log("in loadCities", param);

    try {
      const req = {};
      req.body = {
        shipment: { zipCode: saleEdit.shipment.zipCode },
      };
      await API.post("loadCities", req, { withCredentials: true }).then(
        (response) => {
          console.log("response", response);

          if (response.data.body.list) {
            console.log(
              "response.data.body.list length",
              response.data.body.list.length
            );

            if (response.data.body.list.length > 0) {
              setSaleEdit({
                ...saleEdit,
                shipment: {
                  ...saleEdit.shipment,
                  city: response.data.body.list[0],
                },
              });
              setCities(response.data.body.list);
            }
          } else {
          }
        }
      );
    } catch (error) {
      console.log("error ", error);
    }
  };

  const getProvince = async (param) => {
    console.log("in getProvince", param);

    try {
      const req = {};
      req.body = {
        shipment: { zipCode: saleEdit.shipment.zipCode },
      };
      await API.post("getProvince", req, { withCredentials: true }).then(
        (response) => {
          console.log("response", response);

          if (response.data.body.shipment.province) {
            console.log(
              "response.data.body.shipment.province",
              response.data.body.shipment.province
            );

            setSaleEdit({
              ...saleEdit,
              shipment: {
                ...saleEdit.shipment,
                province: response.data.body.shipment.province,
              },
            });

            // setSaleEdit({
            //   ...saleEdit,
            //   shipment: {
            //     ...saleEdit.shipment,
            //     provinceCode: response.data.body.shipment.province.provinceCode,
            //   },
            // });
            setProvinces(provinces);
          } else {
          }
        }
      );
    } catch (error) {
      console.log("error ", error);
    }
  };

  const loadProvinces = async (param) => {
    console.log("in loadProvinces", param);

    try {
      const req = {};
      req.body = {};
      await API.post("loadProvinces", req, { withCredentials: true }).then(
        (response) => {
          console.log("response", response);

          if (response.data.body.list) {
            console.log(
              "response.data.body.list length",
              response.data.body.list.length
            );

            if (response.data.body.list.length > 0) {
              setProvinces(response.data.body.list);
            }
          } else {
          }
        }
      );
    } catch (error) {
      console.log("error ", error);
    }
  };

  const performSearch = async (param) => {
    console.log("in performSearch", param);

    try {
      const req = {};
      req.body = {
        dateFrom: selectedFromDate,
        dateTo: selectedToDate,
        saleStatusCode: saleStatusCode,
        shipmentStatusCode: shipmentStatusCode,
        searchTxt: customer,
      };
      //axios.defaults.withCredentials = true;
      await API.post("saleSearch", req, { withCredentials: true }).then(
        (response) => {
          console.log("response", response);
          // console.log("response.data", response.data);
          // console.log("response.stringify", JSON.stringify(response.data));

          if (response.data.body.data) {
            console.log(
              "response.data length",
              response.data.body.data[0].length
            );

            if (response.data.body.data[0].length > 0) {
              const rows1 = [];
              for (
                let index = 0;
                index < response.data.body.data[0].length;
                index++
              ) {
                const mo = response.data.body.data[0][index];
                rows1.push(
                  createData(
                    mo[0].shipmentid,
                    mo[3],
                    mo[0].firstname + " " + mo[0].lastname,
                    mo[0].lineid,
                    mo[2].name + " / " + mo[0].zipCode,
                    mo[1].totalAmt.toLocaleString(),
                    mo[1].goodsIssu,
                    mo[1].status.detail,
                    mo[1].statusCode,
                    mo[0].status.detail,
                    mo[0].statusCode
                  )
                );
              }
              setRows(rows1);
            }
          } else {
          }
        }
      );
    } catch (error) {
      console.log("error ", error);
      console.log(error.response);
      console.log(error.response.status);
    }
  };

  const getData = async (param) => {
    console.log("in getData", param);

    try {
      // const res = await fetch('http://localhost:8080/saleInitial', {
      //   credentials: 'include'
      // })
      // .then((response) => response.json())
      // .then((result) => {
      //     console.log("result header", result.header);
      //     console.log("result body", result.body);

      // });

      const res = await API.get("saleInitial", {
        withCredentials: true,
      }).then((response) => {
        console.log("response", response);
        console.log("response.data", response.data);

        console.log("response.stringify", JSON.stringify(response.data));
        setSelectedFromDate(new Date(response.data.body.dateFrom));
        setSelectedToDate(new Date(response.data.body.dateTo));
        setSaleStatusCode(response.data.body.saleStatusCode);
        setShipmentStatusCode(response.data.body.shipmentStatusCode);
        setCustomer(response.data.body.searchTxt);
      });
    } catch (error) {
      console.log("error ", error);
      console.log(error.response);
      console.log(error.response.status);
    }
  };

  const handleClickSearch2 = async (event, name) => {
    try {
      const response = await axios
        .get("http://localhost:8080/public/date/now")
        .then((res) => {
          console.log("res", res);
          console.log("res.data", res.data);
        });

      // console.log("res", res);
      // console.log("in res: OK", new Date());
      if (response && response.data) {
        console.log("res.data: ", response.data);
      } else {
        console.log("Unable to fetch");
      }
    } catch (error) {
      console.log("error ", error);
    }
  };

  const handleClickReset = (event, name) => {
    setRows(rows0);
    console.log("in handleClickReset");
    getData("ok");
  };

  const handleClickLogin = (event, name) => {
    console.log("in handleClickLogin");
    setDataToModal("true" + new Date());
  };

  const handleClickSearch = (event, name) => {
    setRows([]);

    console.log("in handleClickSearch");
    performSearch("ok");
    parentToChild();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleCancelDialogClick = () => {
    console.log("in handleCancelDialogClick");
    setOpenSaleEditDialog(false);
  };

  const handleUpdateDialogClick = () => {
    console.log("in handleUpdateDialogClick");
    setOpenConfirmUpdateDialog(null);

    if (saleEdit.shipment.shipmentid) {
      updateSale();
    } else {
      // Validate
      if (saleItemsNew.length === 0) {
        openErrorDialog("Please choose at least 1 product");
        return;
      }

      createNewSale();
    }
  };

  const handleDeleteDialogClick = async () => {
    console.log("in handleDeleteDialogClick");
    setOpenConfirmDeleteDialog(null);

    try {
      const req = {};
      req.body = {
        shipmentid: saleEdit.shipment.shipmentid,
      };

      await API.post("deleteSale", req, { withCredentials: true }).then(
        (response) => {
          console.log("response", response);
          openSuccessDialog('Delete Success');
          setOpenSaleEditDialog(false);
          performSearch("ok from delete");
        }
      );
    } catch (error) {
      console.log("error ", error);
      openErrorDialog('Delete Error');
    }
  };
  
  const openErrorDialog = (txt) => {
    console.log("in openErrorDialog", txt);
    setDataToCommonDialog(["error", txt, "" + new Date()]);
  };

  const openSuccessDialog = (txt) => {
    console.log("in openSuccessDialog", txt);
    setDataToCommonDialog(["success", txt, "" + new Date()]);
  };

  const handleClickGoodIssue = () => {
    console.log("in handleClickGoodIssue");
    updateGoodIssue();
  };

  const handleChangeSaleStatus = (event) => {
    console.log("in handleChangeSaleStatus");
  };

  const handleChangeShipmentStatus = (event) => {
    console.log("in handleChangeShipmentStatus");
    // setSaleStatusCode(event.target.value);
  };
  const handleClickMenuSaleStatus = (event) => {
    console.log("in handleClickMenuSaleStatus");
  };

  const handleEditClick = (event, name) => {
    console.log("handleEditClick", name);
    setEditSaleid(name);
    setSaleItemsNew([]);
    loadSale(name);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const firstNameChange = (e) => {
    console.log("firstNameChange", e);
  };

  const handleAddNewSaleItem = (e) => {
    // console.log(" stringify", JSON.stringify(e));
    setSaleItemsNew([...saleItemsNew, e]);
  };

  const handleDoneLogin = (e) => {
    console.log(" handleDoneLogin stringify", JSON.stringify(e));
  };

  function handleClickDelete(test, prodRepid) {
    console.log("handleClickDelete", test, prodRepid);
    // console.log("saleItemsNew", saleItemsNew);
    setSaleItemsNew([
      ...saleItemsNew.slice(0, test),
      ...saleItemsNew.slice(test + 1),
    ]);
    // var array = [...saleItemsNew];
    //const array = saleItemsNew.slice(0, 1);
    var i = 0;
    for (i = 0; i < saleItemsNew.length; i++) {
      var saleItemNew = saleItemsNew[i];
      console.log("saleItemNew", saleItemNew.prodRepid);
    }
    // var array = JSON.parse(JSON.stringify(saleItemsNew));
    // var index = array.indexOf(test);
    // var index = -1;
    // console.log("array", array);
    // if (index !== -1) {
    //   array.splice(index, 1);
    //   setSaleItemsNew([...saleItemsNew, array]);
    // } else {
    //   array.splice(test, 1);
    // }
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const parentToChild = () => {
    console.log("in parentToChild");
    setDataToModal(
      "This is data from Parent Component to the Child Component."
    );
  };

  const saleStatuses = [
    { statusCode: 1000, label: "ค้างชำระ" },
    { statusCode: 1100, label: "ชำระแล้ว" },
    { statusCode: 1200, label: "ชำระบางส่วน" },
    { statusCode: 1800, label: "ยกเลิก" },
    { statusCode: 1900, label: "ลบ" },
  ];

  const shipmentStatuses = [
    { statusCode: 3000, label: "ระหว่างดำเนินการ", className: "secondary" },
    { statusCode: 3001, label: "จัดส่งแล้ว", className: "primary" },
    { statusCode: 3002, label: "ถึงผู้รับแล้ว", className: "default" },
  ];

  const provinces2 = ["Bangkok", "หนองคาย", "นนทบุรี"];

  const renderSaleStatusLabel = (saleStatusCode) => {
    let label = "N/A";
    for (var i = 0; i < saleStatuses.length; i++) {
      if (saleStatusCode === saleStatuses[i].statusCode) {
        label = saleStatuses[i].label;
        break;
      }
    }
    return label;
  };

  const renderSaleStatusClassname = (saleStatusCode) => {
    if (saleStatusCode === 1000) {
      return "secondary";
    } else if (saleStatusCode === 1100) {
      return "primary";
    } else {
      return "default";
    }
  };

  const renderShipmentStatusLabel = (statusCode) => {
    let label = "N/A";
    for (var i = 0; i < shipmentStatuses.length; i++) {
      if (statusCode === shipmentStatuses[i].statusCode) {
        label = shipmentStatuses[i].label;
        break;
      }
    }
    return label;
  };

  const renderShipmentStatusClassname = (statusCode) => {
    let className = "default";
    for (var i = 0; i < shipmentStatuses.length; i++) {
      if (statusCode === shipmentStatuses[i].statusCode) {
        className = shipmentStatuses[i].className;
        break;
      }
    }
    return className;
  };

  const handleDoneCommonDialog = (e) => {
    console.log(" handleDoneCommonDialog", JSON.stringify(e));
  };

  return (
    <Container>
      <CommonDialog
        dataToChild={dataToCommonDialog}
        onDone={handleDoneCommonDialog}
      />
      <Dialog
        fullScreen
        open={openSaleEditDialog}
        onKeyDown={(e) => {
          //console.log("onKeyDown", e);
          if (e.keyCode === 27) {
            setOpenSaleEditDialog(false);
          }
        }}
        onClose={(e) => {
          console.log("onClose", e);
          setSaleItemsNew([]);
          setSaleItems([]);
        }}
      >
        <AppBar className="{editSaleDialogClasses}">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCancelDialogClick}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="subtitle1"
              className={editSaleDialogClasses.title}
            >
              {saleEdit.shipment.shipmentid
                ? "Shipment: " +
                  editSaleid +
                  " Date: (" +
                  DateTime.fromMillis(
                    saleEdit.shipment.recordDateTime
                  ).toFormat("ff") +
                  ")"
                : "New Shipment/Sale"}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleCancelDialogClick}>
              Close
            </Button>
          </Toolbar>
        </AppBar>

        <DialogTitle id="alert-dialog-slide-title">{"TBR"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="custFirstname"
                label="Customer Firstname"
                type="text"
                value={saleEdit.shipment.firstname}
                onChange={(e) => {
                  setSaleEdit({
                    ...saleEdit,
                    shipment: {
                      ...saleEdit.shipment,
                      firstname: e.target.value,
                    },
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="custLastname"
                label="Lastname"
                type="text"
                value={saleEdit.shipment.lastname}
                onChange={(e) => {
                  setSaleEdit({
                    ...saleEdit,
                    shipment: {
                      ...saleEdit.shipment,
                      lastname: e.target.value,
                    },
                  });
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="addr1"
                label="Address"
                type="text"
                value={saleEdit.shipment.addr1}
                onChange={(e) => {
                  setSaleEdit({
                    ...saleEdit,
                    shipment: {
                      ...saleEdit.shipment,
                      addr1: e.target.value,
                    },
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="addr2"
                label="Address 2"
                type="text"
                value={saleEdit.shipment.addr2}
                onChange={(e) => {
                  setSaleEdit({
                    ...saleEdit,
                    shipment: {
                      ...saleEdit.shipment,
                      addr2: e.target.value,
                    },
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                autoFocus
                margin="dense"
                id="zipCode"
                label="Zip Code"
                type="text"
                value={saleEdit.shipment.zipCode}
                onChange={(e) => {
                  setSaleEdit({
                    ...saleEdit,
                    shipment: {
                      ...saleEdit.shipment,
                      zipCode: e.target.value,
                    },
                  });
                }}
                onBlur={(e) => {
                  // loadDistricts
                  console.log("onBlur", e);
                  loadDistricts();
                  loadCities();
                  getProvince();
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                id="combo-box-district"
                options={districts}
                value={saleEdit.shipment.district}
                inputValue={saleEdit.shipment.district}
                onChange={(event, newValue) => {
                  console.log("newValue district", newValue);
                  setSaleEdit({
                    ...saleEdit,
                    shipment: {
                      ...saleEdit.shipment,
                      district: newValue,
                    },
                  });
                }}
                onInputChange={(event, newInputValue) => {
                  console.log("newInputValue district", newInputValue);
                  setSaleEdit({
                    ...saleEdit,
                    shipment: {
                      ...saleEdit.shipment,
                      district: newInputValue,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"District"}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                id="combo-box-city"
                options={cities}
                value={saleEdit.shipment.city}
                inputValue={saleEdit.shipment.city}
                onChange={(event, newValue) => {
                  console.log("newValue city", newValue);
                  setSaleEdit({
                    ...saleEdit,
                    shipment: {
                      ...saleEdit.shipment,
                      city: newValue,
                    },
                  });
                }}
                onInputChange={(event, newInputValue) => {
                  console.log("newInputValue city", newInputValue);
                  setSaleEdit({
                    ...saleEdit,
                    shipment: {
                      ...saleEdit.shipment,
                      city: newInputValue,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} label={"City"} variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                id="combo-box-demo"
                options={provinces}
                value={saleEdit.shipment.province.name}
                inputValue={saleEdit.shipment.province.name}
                onChange={(event, newValue) => {
                  console.log("newValue", newValue);
                  setSaleEdit({
                    ...saleEdit,
                    shipment: {
                      ...saleEdit.shipment,
                      province: {
                        ...saleEdit.shipment.province,
                        name: newValue,
                      },
                    },
                  });
                }}
                onInputChange={(event, newInputValue) => {
                  console.log("newInputValue", newInputValue);
                  setSaleEdit({
                    ...saleEdit,
                    shipment: {
                      ...saleEdit.shipment,
                      province: {
                        ...saleEdit.shipment.province,
                        name: newInputValue,
                      },
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      "Province (" +
                      saleEdit.shipment.province.provinceCode +
                      ")"
                    }
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                autoFocus
                margin="dense"
                id="lineid"
                label="Line ID"
                type="text"
                value={saleEdit.shipment.lineid}
                onChange={(e) => {
                  setSaleEdit({
                    ...saleEdit,
                    shipment: {
                      ...saleEdit.shipment,
                      lineid: e.target.value,
                    },
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                autoFocus
                margin="dense"
                id="phone"
                label="Phone"
                type="text"
                value={saleEdit.shipment.phone}
                onChange={(e) => {
                  setSaleEdit({
                    ...saleEdit,
                    shipment: {
                      ...saleEdit.shipment,
                      phone: e.target.value,
                    },
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="email"
                label="Email"
                type="email"
                value={saleEdit.shipment.email}
                onChange={(e) => {
                  setSaleEdit({
                    ...saleEdit,
                    shipment: {
                      ...saleEdit.shipment,
                      email: e.target.value,
                    },
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                rows={3}
                autoFocus
                margin="dense"
                id="remark"
                label="Remark"
                type="text"
                value={saleEdit.sale.remark}
                onChange={(e) => {
                  setSaleEdit({
                    ...saleEdit,
                    sale: {
                      ...saleEdit.sale,
                      remark: e.target.value,
                    },
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <p>
                รายการสั่งซื้อ{" "}
                <NewSaleItemDialog
                  onFirstNameChange={firstNameChange}
                  onDone={handleAddNewSaleItem}
                />
              </p>
              <Paper elevation={3} style={{ padding: "10px" }}>
                <ImageList
                  rowHeight={180}
                  className={saleItemClasses.imageList}
                >
                  {saleItems.map((item, index) => (
                    <ImageListItem key={item.saleItemid}>
                      <img
                        src={item.product.defaultFileImage.url}
                        alt={item.product.name}
                      />
                      <ImageListItemBar
                        title={item.productRep.prodRepAttrValue.category.detail}
                        subtitle={
                          <span>
                            {item.qty} ตัว {item.amount} บาท
                            <br />
                            {item.product.name}
                          </span>
                        }
                        actionIcon={
                          <FormControlLabel
                            style={{ color: "white" }}
                            control={
                              <Switch
                                value={item.markAsDelete}
                                //checked={item.markAsDelete}
                                onChange={(e) => {
                                  console.log(
                                    "e.target.checked",
                                    e.target.checked
                                  );
                                  let item2s = [...saleItems];
                                  let item2 = { ...item2s[index] };
                                  item2.markAsDelete = e.target.checked;
                                  item2s[index] = item2;
                                  setSaleItems(item2s);
                                }}
                              />
                            }
                            label="ลบ"
                          />
                        }
                      />
                    </ImageListItem>
                  ))}
                  {saleItemsNew.map((item, index) => (
                    <ImageListItem key={index}>
                      <img
                        src={item.product.defaultFileImage.url}
                        alt={item.product.name}
                      />
                      <ImageListItemBar
                        title={item.productName}
                        subtitle={
                          <span>
                            {item.qty} ตัว {item.amount} บาท
                            <br />
                            {item.productRepid} {item.product.name}
                          </span>
                        }
                        actionIcon={
                          // <IconButton style={{ color: "white" }} value={index} onClick={(e) => {
                          //     console.log('delete', e);
                          //     var array = [...saleItemsNew];
                          //     var index = array.indexOf(e.target.value);
                          //     console.log('delete2', e.target.value);
                          //     if (index !== -1) {
                          //       array.splice(index, 1);
                          //       setSaleItemsNew([ ...saleItemsNew, array ]);
                          //     }

                          //   }}>
                          //   <DeleteIcon value={index}/>
                          // </IconButton>
                          <Button
                            color="primary"
                            variant="contained"
                            value={"test1"}
                            startIcon={<DeleteIcon />}
                            onClick={() =>
                              handleClickDelete(index, item.prodRepid)
                            }
                            // onClick={(e) => {
                            //   console.log("delete", e);
                            //   var array = [...saleItemsNew];
                            //   var index = array.indexOf(e.target.value);
                            //   console.log("delete2", e.target.value);
                            //   if (index !== -1) {
                            //     array.splice(index, 1);
                            //     setSaleItemsNew([...saleItemsNew, array]);
                            //   }
                            // }}
                          >
                            Delete
                          </Button>
                        }
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              </Paper>
              <p>ราคา</p>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Shipping"
                    type="text"
                    value={saleEdit.sale.shippingAmt}
                    onChange={(e) => {
                      setSaleEdit({
                        ...saleEdit,
                        sale: {
                          ...saleEdit.sale,
                          shippingAmt: e.target.value,
                        },
                      });
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Total Amount"
                    type="text"
                    value={saleEdit.sale.totalAmt}
                    onChange={(e) => {
                      setSaleEdit({
                        ...saleEdit,
                        sale: {
                          ...saleEdit.sale,
                          totalAmt: e.target.value,
                        },
                      });
                    }}
                  />

                  <Button
                    onClick={() => {
                      let total = saleEdit.sale.shippingAmt
                        ? saleEdit.sale.shippingAmt
                        : 0;
                      console.log("total", total);
                      if (saleItems && saleItems.length > 0) {
                        for (var i = 0; i < saleItems.length; i++) {
                          let saleItem = saleItems[i];
                          total = total + saleItem.qty * saleItem.amount;
                        }
                      }
                      if (saleItemsNew && saleItemsNew.length > 0) {
                        for (var i = 0; i < saleItemsNew.length; i++) {
                          let saleItemNew = saleItemsNew[i];
                          total = total + saleItemNew.qty * saleItemNew.amount;
                        }
                      }
                      setSaleEdit({
                        ...saleEdit,
                        sale: {
                          ...saleEdit.sale,
                          totalAmt: total,
                        },
                      });
                    }}
                    color="primary"
                    variant="outlined"
                  >
                    Calculate
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ThemeProvider theme={statusTheme}>
            <Chip
              label={renderSaleStatusLabel(saleEdit.sale.statusCode)}
              clickable
              color={renderSaleStatusClassname(saleEdit.sale.statusCode)}
              onClick={(event) => {
                setShouldOpenMenuSaleStatus(event.currentTarget);
              }}
              icon={<AttachMoneyIcon />}
            />
            <Menu
              id="lock-menu"
              anchorEl={shouldOpenMenuSaleStatus}
              keepMounted
              open={Boolean(shouldOpenMenuSaleStatus)}
              onClose={handleCloseMenuSaleStatus}
            >
              {saleStatuses.map((row, index) => (
                <MenuItem
                  key={row.statusCode}
                  value={row.statusCode}
                  onClick={(event) => handleMenuItemClickSaleStatus(event)}
                >
                  {row.label}
                </MenuItem>
              ))}
            </Menu>
            <Chip
              label={renderShipmentStatusLabel(saleEdit.shipment.statusCode)}
              clickable
              color={renderShipmentStatusClassname(
                saleEdit.shipment.statusCode
              )}
              onClick={(event) => {
                setShouldOpenMenuShipmentStatus(event.currentTarget);
              }}
              icon={<LocalShippingIcon />}
            />
            <Menu
              id="lock-menu2"
              anchorEl={shouldOpenMenuShipmentStatus}
              keepMounted
              open={Boolean(shouldOpenMenuShipmentStatus)}
              onClose={handleCloseMenuShipmentStatus}
            >
              {shipmentStatuses.map((row, index) => (
                <MenuItem
                  key={row.statusCode}
                  value={row.statusCode}
                  onClick={(event) => handleMenuItemClickShipmentStatus(event)}
                >
                  {row.label}
                </MenuItem>
              ))}
            </Menu>
            <Chip
              label={saleEdit.sale.goodsIssu ? "YES" : "NO"}
              clickable
              color={saleEdit.sale.goodsIssu ? "primary" : "secondary"}
              icon={<StoreIcon />}
            />
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClickGoodIssue}
            >
              {saleEdit.sale.goodsIssu ? "Cancel Good Issue" : "Good Issue"}
            </Button>
          </ThemeProvider>

          <div style={{ flex: "1 0 0" }} />

          <Button
            // onClick={handleUpdateDialogClick}
            onClick={(e) => {
              setOpenConfirmUpdateDialog(e.currentTarget);
            }}
            color="primary"
            variant="contained"
            startIcon={<SaveIcon />}
          >
            Update
          </Button>

          <Menu
            anchorEl={openConfirmUpdateDialog}
            keepMounted
            open={Boolean(openConfirmUpdateDialog)}
            onClose={(e) => {
              setOpenConfirmUpdateDialog(null);
            }}
          >
            <MenuItem onClick={handleUpdateDialogClick}>
              Confirm Update
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                setOpenConfirmUpdateDialog(null);
              }}
            >
              Cancel
            </MenuItem>
          </Menu>

          <Button
            onClick={handleCancelDialogClick}
            color="secondary"
            variant="outlined"
            startIcon={<CancelIcon />}
          >
            Cancel
          </Button>

          <Button
            onClick={(e) => {
              setOpenConfirmDeleteDialog(e.currentTarget);
            }}
            color="secondary"
            variant="contained"
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>

          <Menu
            anchorEl={openConfirmDeleteDialog}
            keepMounted
            open={Boolean(openConfirmDeleteDialog)}
            onClose={(e) => {
              setOpenConfirmDeleteDialog(null);
            }}
          >
            <MenuItem onClick={handleDeleteDialogClick}>
              Confirm Delete
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                setOpenConfirmDeleteDialog(null);
              }}
            >
              Cancel
            </MenuItem>
          </Menu>
        </DialogActions>
      </Dialog>
      <main style={{ padding: "1rem 0" }}>
        <h2>Sales</h2>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper} elevation={4}>
                <LoginModal
                  parentToChild={dataToModal}
                  onDone={handleDoneLogin}
                />
                {/* <button type="button" onClick={handleClickLogin}>
                  Login
                </button> */}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="dateFrom"
                    label="From Date"
                    format="dd/MM/yyyy"
                    value={selectedFromDate}
                    onChange={handleFromDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="dateTo"
                    label="To Date"
                    format="dd/MM/yyyy"
                    value={selectedToDate}
                    onChange={handleToDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
                <FormControl className={dropDownClasses.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    สถานะการสั่งซื้อ
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={saleStatusCode}
                    onChange={handleSaleStatusChange}
                    autoWidth
                  >
                    <MenuItem value={-1}>---เลือก---</MenuItem>
                    <MenuItem value={1000}>ค้างชำระ</MenuItem>
                    <MenuItem value={1100}>ชำระเงินแล้ว</MenuItem>
                    <MenuItem value={1200}>ชำระบางส่วน</MenuItem>
                    <MenuItem value={1800}>ยกเลิก</MenuItem>
                    <MenuItem value={1900}>ลบ</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className={dropDownClasses.formControl}>
                  <InputLabel>สถานะการส่ง</InputLabel>
                  <Select
                    value={shipmentStatusCode}
                    onChange={(e) => {
                      setShipmentStatusCode(e.target.value);
                    }}
                    autoWidth
                  >
                    <MenuItem value={-1}>---เลือก---</MenuItem>
                    <MenuItem value={3000}>ระหว่างดำเนินการ</MenuItem>
                    <MenuItem value={3001}>จัดส่งแล้ว</MenuItem>
                    <MenuItem value={3002}>ถึงผู้รับแล้ว</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Customer"
                  type="text"
                  value={customer}
                  onChange={(e) => {
                    setCustomer(e.target.value);
                  }}
                />
                <div className={buttonClasses.root}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClickReset}
                  >
                    Reset
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickSearch}
                  >
                    Search
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={(e) => {
                      newSale();
                    }}
                  >
                    New Sale
                  </Button>
                  {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickSearch2}
                  >
                    Get Date Public
                  </Button> */}
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <div className={tableClasses.root}>
                <Paper className={tableClasses.paper} elevation={2}>
                  <EnhancedTableToolbar numSelected={selected.length} />
                  <TableContainer>
                    <Table
                      stickyHeader
                      className={tableClasses.table}
                      aria-labelledby="tableTitle"
                      size={dense ? "small" : "medium"}
                      aria-label="enhanced table"
                    >
                      <EnhancedTableHead
                        classes={tableClasses}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                      />
                      <TableBody>
                        {stableSort(rows, getComparator(order, orderBy))
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => {
                            const isItemSelected = isSelected(row.name);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                onClick={(event) =>
                                  handleClick(event, row.name)
                                }
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.name}
                                selected={isItemSelected}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    checked={isItemSelected}
                                    inputProps={{ "aria-labelledby": labelId }}
                                  />
                                </TableCell>
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                >
                                  {row.name}
                                  <IconButton
                                    aria-label="delete"
                                    className={classes.margin}
                                    size="small"
                                    onClick={(event) =>
                                      handleEditClick(event, row.name)
                                    }
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                  <IconButton
                                    className={classes.margin}
                                    size="small"
                                    onClick={(event) => {
                                      // handleEditClick(event, row.name)
                                      newSale(row.name);
                                    }}
                                  >
                                    <AddCircleOutlineIcon fontSize="small" />
                                  </IconButton>
                                </TableCell>
                                <TableCell align="right">
                                  {row.calories}
                                </TableCell>
                                <TableCell align="left">
                                  {row.customer}
                                </TableCell>
                                <TableCell align="left">{row.line}</TableCell>
                                <TableCell align="right">
                                  {row.cellProvince}
                                </TableCell>
                                <TableCell align="right">
                                  {row.totalAmt}
                                </TableCell>
                                <TableCell align="right">
                                  <Chip
                                    size="small"
                                    variant="outlined"
                                    label={
                                      row.goodsIssu === true
                                        ? "YES"
                                        : row.goodsIssu === false
                                        ? "NO"
                                        : "-"
                                    }
                                    color={
                                      row.goodsIssu === true
                                        ? "primary"
                                        : row.goodsIssu === false
                                        ? "secondary"
                                        : "default"
                                    }
                                  />
                                </TableCell>
                                <TableCell align="right">
                                  <Chip
                                    size="small"
                                    label={row.saleStatus}
                                    color={
                                      row.saleStatusCode === 1100
                                        ? "primary"
                                        : row.saleStatusCode === 1000
                                        ? "secondary"
                                        : "default"
                                    }
                                  />
                                </TableCell>
                                <TableCell align="right">
                                  <Chip
                                    size="small"
                                    label={row.shipmentStatus}
                                    color={
                                      row.shipmentStatusCode === 3001
                                        ? "primary"
                                        : row.shipmentStatusCode === 3000
                                        ? "secondary"
                                        : "default"
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow
                            style={{ height: (dense ? 33 : 53) * emptyRows }}
                          >
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[
                      10,
                      25,
                      100,
                      { label: "All", value: -1 },
                    ]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
                <FormControlLabel
                  control={
                    <Switch checked={dense} onChange={handleChangeDense} />
                  }
                  label="Dense padding"
                />
                <Grid
                  spacing={3}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="baseline"
                >
                  <Grid item xs>
                    <Button
                      variant="contained"
                      onClick={printEnvelop}
                      color="primary"
                    >
                      Print
                    </Button>
                  </Grid>
                  <Grid item xs>
                    <Button
                      variant="outlined"
                      onClick={updateShipmentToShiped}
                      color="secondary"
                    >
                      Update จัดส่งแล้ว
                    </Button>
                  </Grid>
                  <Grid item xs>
                    <Button
                      variant="contained"
                      onClick={() => {}}
                      color="secondary"
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </main>
      <Dialog
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={openGoodIssueResult}
      >
        <DialogTitle id="confirmation-dialog-title">
          Good Issue Result
        </DialogTitle>
        <DialogContent dividers>OK</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenGoodIssueResult(false);
              setEditSaleid(saleEdit.shipment.shipmentid);
              loadSale(saleEdit.shipment.shipmentid);
            }}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {envelopTxt && envelopTxt.txt !== "" ? (
        <div>
          <Paper elevation={3}>
            <TextField
              multiline
              rows={20}
              autoFocus
              margin="dense"
              label="Envelop"
              type="text"
              value={envelopTxt}
              onChange={(e) => {
                setEnvelopTxt(e.target.value);
              }}
              fullWidth
            />
            <Divider />
            <Button
              onClick={() => {
                navigator.clipboard.writeText(envelopTxt);
              }}
              color="primary"
              variant="contained"
            >
              Copy
            </Button>
          </Paper>
          <br />
          <br />
          <br />
        </div>
      ) : (
        <p>Nothing to print</p>
      )}
    </Container>
  );
}
