import logo from "./logo.svg";
import React, { useRef } from "react";
import clsx from "clsx";
import "./App.css";
import Navbar from "./Navbar";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import Products from "./routes/products";
import Invoices from "./routes/invoices";
import Sales from "./routes/sales";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Toolbar,
  Typography,
} from "@material-ui/core";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import HomeIcon from '@material-ui/icons/Home';
import api from "./services/api";
import { Alert } from "@material-ui/lab";
import LoginModal from "./components/LoginModal";
import { ShoppingBasket } from "@material-ui/icons";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import StorefrontIcon from '@material-ui/icons/Storefront';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

const useStyles2 = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function App() {
  const classes = useStyles();
  const classes2 = useStyles2();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [loggedInUser, setLoggedInUser] = React.useState('N/A');
  const [openGlobalSuccess, setOpenGlobalSuccess] = React.useState(false);
  const [globalSuccessTxt, setGlobalSuccessTxt] = React.useState("Success!");
  const [openGlobalError, setOpenGlobalError] = React.useState(false);
  const [globalErrorTxt, setGlobalErrorTxt] = React.useState("Error!");
  const [dataToModal, setDataToModal] = React.useState("false");
  const didMount = useRef(false);

  React.useEffect(() => {
    // Return early, if this is the first render:
    console.log("Check first render?");
    if (!didMount.current) {
      verifyLogin();
      return (didMount.current = true);
    }
    // Paste code to be executed on subsequent renders:
    console.log("Do something after counter has changed", loggedIn);
  }, [loggedIn]);

  const verifyLogin = async (param) => {
    console.log("in verifyLogin", param);

    try {
      const req = {};
      req.body = {};
      await api
        .post("me", req, { withCredentials: true })
        .then((response) => {
          console.log("verifyLogin response", response.data);
          setLoggedIn(true);
          setLoggedInUser(response.data);
        });
    } catch (error) {
      console.log("error ", error.response.status);

      var txt = "Error " + error.response.status;
      console.log("txt", txt);
      if (error.response.status === 401) {
        showError(txt);
      }
    }
  };

  const showError = (txt) => {
    console.log("in showError", txt);
    setGlobalErrorTxt(txt);
    setOpenGlobalError(true);
  };

  const showSuccess = (txt) => {
    console.log("in showSuccess", txt);
    setGlobalSuccessTxt(txt);
    setOpenGlobalSuccess(true);
  };

  const handleCloseGlobalError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenGlobalError(false);
    setGlobalErrorTxt("Some Error");
  };

  const handleCloseGlobalSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenGlobalSuccess(false);
    setGlobalSuccessTxt("Success!");
  };

  const handleClickLogin = (event, name) => {
    console.log("in handleClickLogin2");
    setDataToModal('true' + new Date());
  };
  
  const handleClickLogout = (event) => {
    console.log("in handleClickLogout", event);

    try {
      const req = {};

      api.post("logout", req, { withCredentials: true }).then((response) => {
        console.log("logout response", response.data);
        showSuccess("Logout OK");
        setLoggedIn(false);
        setLoggedInUser('');
      });
    } catch (error) {
      console.log("error ", error.response.status);

      var txt = "Error " + error.response.status;
      console.log("txt", txt);
      if (error.response.status === 401) {
        showError(txt);
      }
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleDoneLogin = (e) => {
    console.log(" handleDoneLogin stringify", JSON.stringify(e));
    //setLoggedIn(true);
    verifyLogin();
  };  

  const handleCheckAuthen = (e) => {
    console.log(" handleCheckAuthen stringify", JSON.stringify(e));
    return loggedInUser;
  };  

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {/* {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))} */}
        <ListItem button key="home">
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <Link to="/">Home</Link>
        </ListItem>
        <ListItem button key="sales">
          <ListItemIcon>
            <LocalShippingIcon />
          </ListItemIcon>
          <Link to="/sales">Sales</Link>
        </ListItem>
        <ListItem button key="products">
          <ListItemIcon>
            <StorefrontIcon />
          </ListItemIcon>
          <Link to="/products">Products</Link>
        </ListItem>
      </List>
      <Divider />
      <List>
        {/* {["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))} */}
      </List>
      
    </div>
  );
  

  return (
    <div className={classes2.root}>
      <LoginModal parentToChild={dataToModal} onDone={handleDoneLogin} checkAuthen={handleCheckAuthen}/>
      {/* <Navbar></Navbar> */}
      {/* <List></List> */}
      <AppBar position="fixed">
        <Toolbar>
          <IconButton className={classes2.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer("left", true)}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }} className={classes2.title}>
            KKCMS React
          </Typography>

          {loggedIn ? (<Button color="inherit" onClick={handleClickLogout}>({loggedInUser}) Logout</Button>) 
          : (<Button color="inherit" onClick={handleClickLogin}>Login</Button>
        )}
          
        </Toolbar>
      </AppBar>

      <div>
        {["left", "right", "top", "bottom"].map((anchor) => (
          <React.Fragment key={anchor}>
            <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </div>

      <nav
        style={{
          borderBottom: "solid 1px",
          paddingBottom: "1rem",
        }}
      >
        <Link to="/">Home</Link> |<Link to="/sales">Sales</Link> |
        <Link to="/invoices">Invoices</Link> |
        <Link to="/products">Products</Link>
      </nav>

      <Routes>
        <Route path="/" element={<Layout />} />
        <Route path="sales" element={<Sales />} />
        <Route path="products" element={<Products />} />
        <Route path="invoices" element={<Invoices />} />
      </Routes>

      <Snackbar
        open={openGlobalError}
        onClose={handleCloseGlobalError}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        key={"openGlobalError"}
      >
        <Alert onClose={handleCloseGlobalError} severity="error">
          {globalErrorTxt}
        </Alert>
      </Snackbar>

      <Snackbar
        open={openGlobalSuccess}
        onClose={handleCloseGlobalSuccess}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        key={"openGlobalSuccess"}
      >
        <Alert onClose={handleCloseGlobalSuccess} severity="success">
          {globalSuccessTxt}
        </Alert>
      </Snackbar>
    </div>

    /* <div className="App">
      
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div> */
  );
}

function Layout() {
  return (
    <div>
      <h3>Home</h3>
      {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}
      <Link to="/">Home</Link> | <Link to="/sales">Sales</Link> |{" "}
      <Link to="/products">Products</Link>
      <hr />
      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
      <Outlet />
    </div>
  );
}
