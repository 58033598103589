import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  MenuItem,
  Paper,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import API from "../services/api";
import { green } from "@material-ui/core/colors";
import MUIEditor, { MUIEditorState, toHTML } from "react-mui-draft-wysiwyg";
import { EditorState } from "draft-js";
import { ContentState } from "draft-js";
import { convertFromHTML } from "draft-js";
import { useDropzone } from "react-dropzone";
import { Alert } from "@material-ui/lab";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows2 = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  // createData("Frozen yoghurt", 159, 6.0, 24),
  // createData("Ice cream sandwich", 237, 9.0, 37),
];

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const CssTextField = withStyles({
  input: {
    color: "white",
  },
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "green",
      },
    },
  },
})(TextField);

export default function Products() {
  const [prodCat, setProdCat] = React.useState(-1);
  const [prodCats, setProdCats] = React.useState([]);
  const [includeHidden, setIncludeHidden] = React.useState(false);
  const [prods, setProds] = React.useState([]);
  const [prodid, setProdid] = React.useState(-1);
  const [prod, setProd] = React.useState({
    shortDesc: "",
    defaultFileImage: { url: "" },
  });
  const [tableHeads, setTableHeads] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openWaist, setOpenWaist] = React.useState(false);
  const [prodReps, setProdReps] = React.useState([]);
  const [waistCats, setWaistCats] = React.useState([]);
  const [newWaistCatCode, setNewWaistCatCode] = React.useState(-1);
  const [editorState, setEditorState] = React.useState(
    // MUIEditorState.createEmpty()
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML("<p>My initial content.</p>")
      )
    )
  );
  const [html, setHtml] = React.useState("");
  const [shortDesc, setShortDesc] = React.useState("");
  const [prodEdit, setProdEdit] = React.useState({ name: "", unitPrice: "0" });

  const [openPhotos, setOpenPhotos] = React.useState(false);
  const [fileImages, setFileImages] = React.useState([]);
  const [openSnackbarSuccess, setOpenSnackbarSuccess] = React.useState(false);
  const [openSnackbarError, setOpenSnackbarError] = React.useState(false);

  const onChange = (newState) => {
    setEditorState(newState);
  };
  const convertToHTML = () => {
    const stateHtml = toHTML(editorState.getCurrentContent());
    setHtml(stateHtml);
  };
  const convertBackToHTML = () => {
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(html))
      )
    );
  };

  React.useEffect(() => {
    loadProductCategories();
  }, []);

  const loadProductCategories = async (param) => {
    console.log("in loadProductCategories", param);

    try {
      const req = {};
      req.body = {};
      const res = await API.get("findProductCategory", {
        withCredentials: true,
      }).then((response) => {
        setProdCats(response.data.body.list);
        setProdCat(response.data.body.list[0].categoryCode);
      });
    } catch (error) {
      console.log("error ", error);
    }
  };

  const loadProducts = async (param) => {
    console.log("in loadProducts", param);

    try {
      const req = {};
      req.body = {
        includeHidden: includeHidden,
        productCatCode: prodCat,
      };
      await API.post("findProduct", req, { withCredentials: true }).then(
        (response) => {
          setProds(response.data.body.list);
          setProdid(response.data.body.list[0].productid);
        }
      );
    } catch (error) {
      console.log("error ", error);
    }
  };

  const RenderProduct = () => {
    return (
      <div>
        {prod ? (
          <div>
            <img
              src={prod.defaultFileImage.url}
              alt={prod.name}
              style={{ width: "200px" }}
            />
          </div>
        ) : (
          <p>N/A</p>
        )}
      </div>
    );
  };

  const getProduct = async (param) => {
    console.log("in getProduct", param);

    try {
      const req = {};
      req.body = {
        productid: prodid,
      };
      await API.post("getProduct", req, { withCredentials: true }).then(
        (response) => {
          // console.log("response", response);
          let p = response.data.body.objects[0];
          setProd(response.data.body.objects[0]);
          // setEditorState({ "currentContent": response.data.body.objects[0].fullDesc});
          setShortDesc(response.data.body.objects[0].shortDesc);
          setProdEdit({
            ...prodEdit,
            name: p.name,
            productCode: p.productCode,
            uri: p.uri,
            uriName: p.uriName,
            unitPrice: p.unitPrice,
            published: p.published,
            newArrival: p.newArrival,
            recommended: p.recommended,
            productCatCode: p.productCatCode,
          });

          setEditorState(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(response.data.body.objects[0].fullDesc)
              )
            )
          );

          // setProdReps(response.data.body.objects[1]);
          // setProdReps(response.data.body.objects[1], async () => {
          //setProdRepid(response.data.body.objects[1][0]);
          // setProdRepid(prodReps[0].productRepid);
          // });

          // setAmount(response.data.body.objects[0].unitPrice * qty);
        }
      );
    } catch (error) {
      console.log("error ", error);
    }
  };

  const loadProductSize = async (param) => {
    console.log("in loadProductSize", param);

    try {
      const req = {};
      req.body = {
        productid: prodid,
      };
      await API.post("findStock", req, { withCredentials: true }).then(
        (response) => {
          //setProd(response.data.body.objects[0]);
          setTableHeads(response.data.body.objects[0]);
          setRows(response.data.body.objects[1]);
        }
      );
    } catch (error) {
      console.log("error ", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditClick = (event, w, pid) => {
    console.log("handleEditClick", w, pid);
    loadProductRep(w, pid);
  };

  const handleClickAddWaist = (event) => {
    console.log("handleClickAddWaist", event);
    // Load notExist waists
    loadWaist();
  };

  const handleClickPhotos = (event) => {
    console.log("handleClickPhotos", event);
    loadProductPhotos();
  };

  const handleClickUpdateStock = () => {
    console.log("in handleClickUpdateStock");
    updateStock();
  };

  const handleClickUpdateWaist = () => {
    console.log("in handleClickUpdateWaist");
    updateWaist();
  };

  const handleClickUpdateProduct = () => {
    console.log("in handleClickUpdateProduct");
    updateProduct();
  };

  const handleClickUpdateProductPhotos = () => {
    console.log("in handleClickUpdateProductPhotos");
    updateProductPhotos();
  };

  const handleChangeDefaultImage = (e, item, index) => {
    // console.log("in handleChangeDefaultImage", index, item);

    let item2s = [...fileImages];

    for (var i = 0; i < item2s.length; i++) {
      if (i !== index) {
        item2s[i].defaultImage = false;
      } else {
        item2s[i].defaultImage = e.target.checked ? true : false;
      }
    }

    // let item2 = { ...item2s[index] };
    // item2.defaultImage = e.target.checked ? true : false;
    // item2s[index] = item2;

    //console.log("item2s", JSON.stringify(item2s));
    setFileImages(item2s);
  };

  const displayOrders = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ];

  const updateStock = async () => {
    console.log("in updateStock");
    console.log(" prodid", prodid);

    try {
      const req = {};
      req.body = {
        productReplicas: prodReps,
      };

      await API.post("updateStock", req, { withCredentials: true }).then(
        (response) => {
          console.log("response", response);

          setOpen(false);
          loadProductSize();
        }
      );
    } catch (error) {
      console.log("error ", error);
    }
  };

  const updateWaist = async () => {
    console.log("in updateWaist");

    try {
      const req = {};
      req.body = {
        productCatCode: newWaistCatCode,
        productid: prodid,
      };

      await API.post("addWaist", req, { withCredentials: true }).then(
        (response) => {
          console.log("response", response);
        }
      );
    } catch (error) {
      console.log("error ", error);
    }
  };

  const updateProduct = async () => {
    console.log("in updateProduct");

    try {
      const req = {};

      req.body = {
        product: {
          ...prodEdit,
          shortDesc: shortDesc,
          fullDesc: toHTML(editorState.getCurrentContent()),
        },
        productid: prodid,
      };

      await API.post("updateProduct", req, { withCredentials: true }).then(
        (response) => {
          console.log("response", response);
          setOpenSnackbarSuccess(true);
        }
      );
    } catch (error) {
      console.log("error ", error);
      setOpenSnackbarError(true);
    }
  };

  const updateProductPhotos = async () => {
    console.log("in updateProductPhotos");

    try {
      const req = {};

      req.body = {
        fileImages: fileImages,
        productid: prodid,
      };

      await API.post("updateProductPhotos", req, {
        withCredentials: true,
      }).then((response) => {
        console.log("response", response);
        loadProductPhotos();
      });
    } catch (error) {
      console.log("error ", error);
    }
  };

  const loadProductRep = async (w, pid) => {
    // console.log("in loadProductRep", w);
    // console.log("in loadProductRep pid", pid);

    try {
      const req = {};
      req.body = {
        productid: pid,
        dateFrom: w,
      };

      await API.post("getProductReplica", req, { withCredentials: true }).then(
        (response) => {
          setProdReps(response.data.body.list);
        }
      );
    } catch (error) {
      console.log("error ", error);
    }
  };

  const loadWaist = async () => {
    // console.log("in loadProductRep", w);

    try {
      const req = {};
      req.body = {
        productid: prodid,
      };

      await API.post("getWaist", req, { withCredentials: true }).then(
        (response) => {
          setWaistCats(response.data.body.list);
        }
      );
    } catch (error) {
      console.log("error ", error);
    }
  };

  const loadProductPhotos = async () => {
    // console.log("in loadProductPhotos", w);

    try {
      const req = {};
      req.body = {
        productid: prodid,
      };

      await API.post("getProductPhotos", req, { withCredentials: true }).then(
        (response) => {
          setFileImages(response.data.body.list);
        }
      );
    } catch (error) {
      console.log("error ", error);
    }
  };

  const handleClickCopyProduct = async () => {
    // console.log("in loadProductPhotos", w);

    try {
      const req = {};
      req.body = {
        productid: prodid,
      };

      await API.post("copyProduct", req, { withCredentials: true }).then(
        (response) => {
          setOpenSnackbarSuccess(true);
        }
      );
    } catch (error) {
      console.log("error ", error);
      setOpenSnackbarError(true);
    }
  };

  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  function Previews(props) {
    // const [files, setFiles] = useState([]);
    // const [prodCats, setProdCats] = React.useState([]);
    const [files, setFiles] = React.useState([]);

    const { getRootProps, getInputProps } = useDropzone({
      accept: "image/*",
      onDrop: (acceptedFiles) => {
        console.log("acceptedFiles", acceptedFiles);
        let data = new FormData();
        for (var i = 0; i < acceptedFiles.length; i++) {
          let acceptedFile = acceptedFiles[i];
          console.log("acceptedFile", acceptedFile);
          data.append("files", acceptedFile);
        }
        data.append("productid", prodid);

        try {
          //API.post("uploadProductPhoto", data);
          API.post("uploadProductPhoto", data, { withCredentials: true }).then(
            (response) => {
              console.log(response);
              setOpenSnackbarSuccess(true);
              loadProductPhotos();
            }
          );
        } catch (error) {
          console.log("error ", error);
        }

        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });

    const thumbs = files.map((file) => (
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img src={file.preview} style={img} />
        </div>
      </div>
    ));

    // useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    //   files.forEach(file => URL.revokeObjectURL(file.preview));
    // }, [files]);
    React.useEffect(() => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    }, [files]);

    return (
      <section className="container">
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
        </div>
        <aside style={thumbsContainer}>{thumbs}</aside>
      </section>
    );
  }

  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbarSuccess(false);
  };

  const handleCloseSnackbarError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbarError(false);
  };

  return (
    <Container>
      <main style={{ padding: "1px 0" }}>
        <h4>Products</h4>
      </main>

      <Grid
        container
        spacing={3}
        justifyContent="flex-end"
        direction="row"
        alignItems="flex-start"
      >
        <Grid item xs={6}>
          <TextField
            select
            label="Product Category"
            value={prodCat}
            onChange={(event) => {
              setProdCat(event.target.value);
            }}
            fullWidth
          >
            {prodCats.map((option) => (
              <MenuItem key={option.categoryCode} value={option.categoryCode}>
                {option.detail}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Switch
                name="checkedB"
                color="primary"
                value={includeHidden}
                onChange={(event) => {
                  console.log("Switch", event.target.checked);
                  setIncludeHidden(event.target.checked);
                }}
              />
            }
            label="Include Hidden"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              loadProducts();
              setProd(null);
              setProdReps([]);
            }}
          >
            Find Product
          </Button>
        </Grid>
        <Grid item xs={7}>
          <TextField
            select
            label="Products"
            value={prodid}
            onChange={(event) => {
              setProdid(event.target.value);
              console.log("pid", event.target.value);
            }}
            fullWidth
          >
            {prods.map((option) => (
              <MenuItem key={option.productid} value={option.productid}>
                {option.name} ({option.unitPrice} บาท)
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={5}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              getProduct();
            }}
          >
            Choose
          </Button>
        </Grid>
        <Grid item xs={4}>
          <RenderProduct />
        </Grid>

        <Grid item xs>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              getProduct();
              loadProductSize();
            }}
          >
            Load Size
          </Button>
        </Grid>
        <Grid item xs>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={(e) => {
              setOpenWaist(true);
              handleClickAddWaist(e);
            }}
          >
            Edit
          </Button>
        </Grid>
        <Grid item xs>
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={(e) => {
              setOpenPhotos(true);
              handleClickPhotos();
            }}
          >
            Photos
          </Button>
        </Grid>
        <Grid item xs>
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={(e) => {
              handleClickCopyProduct();
            }}
          >
            Copy Product
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead key={"key0"}>
            <TableRow key={"key1"}>
              <StyledTableCell align="left" scope="row" key="k1">
                Waist
                <IconButton
                  aria-label="add"
                  size="small"
                  onClick={(event) => {
                    setOpenWaist(true);
                    handleClickAddWaist(event);
                  }}
                >
                  <AddIcon fontSize="small" style={{ color: green[500] }} />
                </IconButton>
              </StyledTableCell>
              {tableHeads.map((row) => (
                <StyledTableCell
                  component="th"
                  scope="row"
                  align="right"
                  key={row}
                >
                  {row}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody key="b1">
            {rows.map(function (row, index) {
              return (
                <StyledTableRow key={row[0]}>
                  {row.map(function (col2, index2) {
                    if (index2 === 0) {
                      return (
                        <StyledTableCell component="th" scope="row">
                          {row[index2]}
                          <IconButton
                            aria-label="edit"
                            size="small"
                            onClick={(event) => {
                              setOpen(true);
                              handleEditClick(event, row[index2], prodid);
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </StyledTableCell>
                      );
                    } else {
                      return (
                        <StyledTableCell align="right">
                          {row[index2][1]}
                        </StyledTableCell>
                      );
                    }
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          {prod ? prod.name : "N/A"}
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>{prod ? prod.name : "N/A"}</DialogContentText> */}
          {prodReps.map((prodRep, index) => (
            <Grid container spacing={3}>
              <Grid item xs={4}>
                {prodRep.product.name}
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Available"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={prodRep.numAvail}
                  onChange={(e) => {
                    console.log("e.target.value", e.target.value);
                    let item2s = [...prodReps];
                    let item2 = { ...item2s[index] };
                    item2.numAvail = e.target.value;
                    item2s[index] = item2;
                    setProdReps(item2s);
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Add"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={prodRep.numAdd}
                  onChange={(e) => {
                    // console.log("e.target.value", e.target.value);
                    let item2s = [...prodReps];
                    let item2 = { ...item2s[index] };
                    item2.numAdd = e.target.value;
                    item2s[index] = item2;
                    setProdReps(item2s);
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Sold"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={prodRep.numSold}
                  onChange={(e) => {
                    console.log("e.target.value", e.target.value);
                    let item2s = [...prodReps];
                    let item2 = { ...item2s[index] };
                    item2.numSold = e.target.value;
                    item2s[index] = item2;
                    setProdReps(item2s);
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleClickUpdateStock}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openWaist}
        //onClose={handleCloseWaist}
        aria-labelledby="form-dialog-title2"
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title2">
          New Waist / Edit Product
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                select
                label="Waist (Size)"
                value={newWaistCatCode}
                onChange={(event) => {
                  setNewWaistCatCode(event.target.value);
                }}
                fullWidth
              >
                {waistCats.map((option) => (
                  <MenuItem
                    key={option.categoryCode}
                    value={option.categoryCode}
                  >
                    {option.detail}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={8}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleClickUpdateWaist}
              >
                Add Waist
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Product Title"
                type="text"
                value={prodEdit.name}
                onChange={(e) => {
                  setProdEdit({
                    ...prodEdit,
                    name: e.target.value,
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Product Code"
                type="text"
                value={prodEdit.productCode}
                onChange={(e) => {
                  setProdEdit({
                    ...prodEdit,
                    productCode: e.target.value,
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="URI"
                type="text"
                value={prodEdit.uri}
                onChange={(e) => {
                  setProdEdit({
                    ...prodEdit,
                    uri: e.target.value,
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="uriName"
                type="text"
                value={prodEdit.uriName}
                onChange={(e) => {
                  setProdEdit({
                    ...prodEdit,
                    uriName: e.target.value,
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                autoFocus
                margin="dense"
                id="unitPrice"
                label="Unit Price"
                type="text"
                value={prodEdit.unitPrice}
                onChange={(e) => {
                  setProdEdit({
                    ...prodEdit,
                    unitPrice: e.target.value,
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Switch
                    name="published"
                    color="primary"
                    checked={prodEdit.published}
                    onChange={(e) => {
                      setProdEdit({
                        ...prodEdit,
                        published: e.target.checked,
                      });
                    }}
                  />
                }
                label="Published"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Switch
                    name="newArrival"
                    color="primary"
                    checked={prodEdit.newArrival}
                    onChange={(e) => {
                      setProdEdit({
                        ...prodEdit,
                        newArrival: e.target.checked,
                      });
                    }}
                  />
                }
                label="New Arrival"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Switch
                    name="recommended"
                    color="primary"
                    checked={prodEdit.recommended}
                    onChange={(e) => {
                      setProdEdit({
                        ...prodEdit,
                        recommended: e.target.checked,
                      });
                    }}
                  />
                }
                label="Recommended"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                select
                label="Category"
                value={prodEdit.productCatCode}
                onChange={(e) => {
                  setProdEdit({
                    ...prodEdit,
                    productCatCode: e.target.value,
                  });
                }}
                fullWidth
              >
                {prodCats.map((option) => (
                  <MenuItem
                    key={option.categoryCode}
                    value={option.categoryCode}
                  >
                    {option.detail}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="shortDesc"
                label="Short Description"
                type="text"
                value={shortDesc}
                onChange={(e) => {
                  setShortDesc(e.target.value);
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <p>Full Description</p>
              <MUIEditor onChange={onChange} editorState={editorState} />
              {/* editorState={editorState}  */}
            </Grid>
            <Grid item xs={12}>
              <button onClick={convertToHTML}>To HTML</button>
              <button onClick={convertBackToHTML}>From HTML</button>
            </Grid>
            <Grid item xs={12}>
              {/* <div>{html}</div> */}
              <TextField
                multiline
                rows={10}
                autoFocus
                margin="dense"
                id="fullDesc"
                label="fullDesc"
                variant="filled"
                value={html}
                onChange={(e) => {
                  setHtml(e.target.value);
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setOpenWaist(false);
            }}
          >
            Close
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleClickUpdateProduct}
          >
            Update Product
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openWaist}
        //onClose={handleCloseWaist}
        aria-labelledby="form-dialog-title2"
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title2">
          New Waist / Edit Product
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                select
                label="Waist (Size)"
                value={newWaistCatCode}
                onChange={(event) => {
                  setNewWaistCatCode(event.target.value);
                }}
                fullWidth
              >
                {waistCats.map((option) => (
                  <MenuItem
                    key={option.categoryCode}
                    value={option.categoryCode}
                  >
                    {option.detail}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={8}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleClickUpdateWaist}
              >
                Add Waist
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Product Title"
                type="text"
                value={prodEdit.name}
                onChange={(e) => {
                  setProdEdit({
                    ...prodEdit,
                    name: e.target.value,
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Product Code"
                type="text"
                value={prodEdit.productCode}
                onChange={(e) => {
                  setProdEdit({
                    ...prodEdit,
                    productCode: e.target.value,
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="URI"
                type="text"
                value={prodEdit.uri}
                onChange={(e) => {
                  setProdEdit({
                    ...prodEdit,
                    uri: e.target.value,
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="uriName"
                type="text"
                value={prodEdit.uriName}
                onChange={(e) => {
                  setProdEdit({
                    ...prodEdit,
                    uriName: e.target.value,
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                autoFocus
                margin="dense"
                id="unitPrice"
                label="Unit Price"
                type="text"
                value={prodEdit.unitPrice}
                onChange={(e) => {
                  setProdEdit({
                    ...prodEdit,
                    unitPrice: e.target.value,
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Switch
                    name="published"
                    color="primary"
                    checked={prodEdit.published}
                    onChange={(e) => {
                      setProdEdit({
                        ...prodEdit,
                        published: e.target.checked,
                      });
                    }}
                  />
                }
                label="Published"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Switch
                    name="newArrival"
                    color="primary"
                    checked={prodEdit.newArrival}
                    onChange={(e) => {
                      setProdEdit({
                        ...prodEdit,
                        newArrival: e.target.checked,
                      });
                    }}
                  />
                }
                label="New Arrival"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Switch
                    name="recommended"
                    color="primary"
                    checked={prodEdit.recommended}
                    onChange={(e) => {
                      setProdEdit({
                        ...prodEdit,
                        recommended: e.target.checked,
                      });
                    }}
                  />
                }
                label="Recommended"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                select
                label="Category"
                value={prodEdit.productCatCode}
                onChange={(e) => {
                  setProdEdit({
                    ...prodEdit,
                    productCatCode: e.target.value,
                  });
                }}
                fullWidth
              >
                {prodCats.map((option) => (
                  <MenuItem
                    key={option.categoryCode}
                    value={option.categoryCode}
                  >
                    {option.detail}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="shortDesc"
                label="Short Description"
                type="text"
                value={shortDesc}
                onChange={(e) => {
                  setShortDesc(e.target.value);
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <p>Full Description</p>
              <MUIEditor onChange={onChange} editorState={editorState} />
              {/* editorState={editorState}  */}
            </Grid>
            <Grid item xs={12}>
              <button onClick={convertToHTML}>To HTML</button>
              <button onClick={convertBackToHTML}>From HTML</button>
            </Grid>
            <Grid item xs={12}>
              {/* <div>{html}</div> */}
              <TextField
                multiline
                rows={10}
                autoFocus
                margin="dense"
                id="fullDesc"
                label="fullDesc"
                variant="filled"
                value={html}
                onChange={(e) => {
                  setHtml(e.target.value);
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setOpenWaist(false);
            }}
          >
            Close
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleClickUpdateProduct}
          >
            Update Product
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openPhotos}
        aria-labelledby="form-dialog-title3"
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title3">Edit Product Photos</DialogTitle>
        <DialogContent>
          <ImageList cols={4}>
            {fileImages.map((item, index) => (
              <ImageListItem key={item.fileImageid}>
                <img src={item.url} alt={item.filename} />
                <ImageListItemBar
                  title={index + 1 + " - " + item.fileImageid}
                  subtitle={<span>{item.filename}</span>}
                  actionIcon={
                    <div>
                      <CssTextField
                        inputProps={{
                          style: {
                            color: "white !important",
                            backgroundColor: "white",
                          },
                        }}
                        style={{
                          color: "white !important",
                          backgroundColor: "white",
                        }}
                        select
                        value={item.displayOrder}
                        onChange={(e) => {
                          let item2s = [...fileImages];
                          let item2 = { ...item2s[index] };
                          item2.displayOrder = e.target.value;
                          item2s[index] = item2;
                          setFileImages(item2s);
                        }}
                      >
                        {displayOrders.map((option) => (
                          <StyledMenuItem key={option} value={option}>
                            {option}
                          </StyledMenuItem>
                        ))}
                      </CssTextField>

                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            color="primary"
                            value={item.defaultImage}
                            checked={item.defaultImage}
                            onChange={(e) => {
                              handleChangeDefaultImage(e, item, index);
                            }}
                          />
                        }
                        label="Default"
                      />

                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            color="primary"
                            value={item.published}
                            checked={item.published}
                            onChange={(e) => {
                              let item2s = [...fileImages];
                              let item2 = { ...item2s[index] };
                              item2.published = e.target.checked ? true : false;
                              item2s[index] = item2;
                              setFileImages(item2s);
                            }}
                          />
                        }
                        label="Publish"
                      />

                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            color="secondary"
                            value={item.delete}
                            checked={item.delete}
                            onChange={(e) => {
                              let item2s = [...fileImages];
                              let item2 = { ...item2s[index] };
                              item2.delete = e.target.checked ? true : false;
                              item2s[index] = item2;
                              setFileImages(item2s);
                            }}
                          />
                        }
                        label="Delete"
                      />
                    </div>
                  }
                />
              </ImageListItem>
            ))}
          </ImageList>
          <Previews />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setOpenPhotos(false);
            }}
          >
            Close
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleClickUpdateProductPhotos}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbarSuccess}
        autoHideDuration={2000}
        onClose={handleCloseSnackbarSuccess}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        key={"topcenter"}
      >
        <Alert onClose={handleCloseSnackbarSuccess} severity="success">
          Success!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openSnackbarError}
        onClose={handleCloseSnackbarError}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        key={"topcenter2"}
      >
        <Alert onClose={handleCloseSnackbarError} severity="error">
          Error!
        </Alert>
      </Snackbar>
    </Container>
  );
}
