import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import API from "../services/api";
import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";
// import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const useTextFieldStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const handleFormSubmit = (event) => {
  // const data = new FormData(this.form);
  // fetch(this.form.action, {
  //   method: this.form.method,
  //   body: new URLSearchParams(data),
  //   headers: { "X-XSRF-TOKEN": window.getCsrfToken() },
  // })
  //   .then((v) => {
  //     if (v.redirected) window.location = v.url;
  //   })
  //   .catch((e) => console.warn(e));
};

export default function LoginModal({ parentToChild, onDone, checkAuthen }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loginError, setLoginError] = React.useState(false);
  const [loginResult, setLoginResult] = React.useState("N/A");
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");// 20200509#
  
  React.useEffect(() => {
    console.log("parentToChild", parentToChild);

    let auth;
    if (checkAuthen) {
      let auth = checkAuthen();
      console.log("auth", auth);
    }

    if (parentToChild && parentToChild.startsWith("true")) {
    // if (auth && auth !== '' && auth !== 'N/A') {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [parentToChild]);

  const textFieldClasses = useTextFieldStyles();



const doLogin = async (u, p) => {
  console.log("in doLogin", u);

  setLoginError(false);
  setLoginResult("Processing");

  const form1 = {
    auth: {
      username: u,
      password: p,
    },
  };
  try {
    const formData = new FormData();
    formData.append("username", u);
    formData.append("password", p);

    //axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    // const res = await axios.get("http://localhost:8080/date/now", form1).then((res) => {
    const res = await API.post("perform_login", formData, {
      withCredentials: true,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      // console.log("res", res);
      // console.log("res.data", res.data);
      // console.log("res.headers", res.headers);
      setOpen(false);
      onDone({
        res: res.data
      });
    });

    // console.log("res", res);
    // console.log("in res: OK", new Date());
    // if (res.data) {
    //   console.log("res.data: ", res.data);
    // } else {
    //   console.log("Unable to fetch");
    // }
  } catch (error) {
    // console.log(error.response.data);
    // console.log(error.response.status);
    // console.log(error.response.headers);
    // console.log("error ", error);
    // console.log("error response", error.response);

    setLoginResult('Error ' + error.response.status);
    setLoginError(true);
  }

  // axios.get("http://localhost:8080/date/now").then((res) => {
  //   console.log('res', res);
  //   console.log('res.data', res.data);
  // });
};

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLoginClick = () => {
    doLogin(username, password);
  };

  return (
    <div>
      {/* <button type="button" onClick={handleOpen}>
        Login
      </button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">
              Please Login: {parentToChild}{" "}
            </h2>
            <p id="transition-modal-description">
              react-transition-group animates me.
            </p>            
            <form
              className={textFieldClasses.root}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  required
                  id="standard-required"
                  label="Required"
                  value={username}
                  onChange={(event) => {
                    setUsername(event.target.value);
                  }}
                />
                <TextField
                  id="standard-password-input"
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                />
              </div>
            </form>

            <Button
              variant="contained"
              color="primary"
              onClick={handleLoginClick}
            >
              Submit
            </Button>
          </div>
        </Fade>
      </Modal>
      <Snackbar open={loginError} autoHideDuration={5000} onClose={handleClose}><Alert severity="error">{loginResult}</Alert></Snackbar>
    </div>
  );
}
