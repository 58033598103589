import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import API from "../services/api";
import axios from "axios";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import Grid from "@material-ui/core/Grid";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  MenuItem,
  Switch,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const useTextFieldStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

export default function NewSaleItemDialog({ onFirstNameChange, onDone }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [prodCat, setProdCat] = React.useState();
  const [prodCats, setProdCats] = React.useState([]);
  const [includeHidden, setIncludeHidden] = React.useState(false);
  const [prods, setProds] = React.useState([]);
  const [prodid, setProdid] = React.useState();
  const [prod, setProd] = React.useState();
  const [prodReps, setProdReps] = React.useState([]);
  const [prodRepid, setProdRepid] = React.useState();
  const [catDetail, setCatDetail] = React.useState();
  const [qty, setQty] = React.useState(1);
  const [amount, setAmount] = React.useState();

  const textFieldClasses = useTextFieldStyles();

  const loadProductCategories = async (param) => {
    console.log("in loadProductCategories", param);

    try {
      const req = {};
      req.body = {};
      const res = await API.get("findProductCategory", {
        withCredentials: true,
      }).then((response) => {
        // console.log("response", response);
        // console.log("response.data", response.data);
        setProdCats(response.data.body.list);
        setProdCat(response.data.body.list[0].categoryCode);
      });
    } catch (error) {
      console.log("error ", error);
    }
  };

  const loadProducts = async (param) => {
    console.log("in loadProducts", param);

    try {
      const req = {};
      req.body = {
        includeHidden: includeHidden,
        productCatCode: prodCat,
      };
      await API.post("findProduct", req, { withCredentials: true }).then(
        (response) => {
          // console.log("response", response);
          setProds(response.data.body.list);
          setProdid(response.data.body.list[0].productid);
          setProdRepid(null);
        }
      );
    } catch (error) {
      console.log("error ", error);
    }
  };

  const getProduct = async (param) => {
    console.log("in getProduct", param);

    try {
      const req = {};
      req.body = {
        productid: prodid,
      };
      await API.post("getProduct", req, { withCredentials: true }).then(
        (response) => {
          // console.log("response", response);
          setProd(response.data.body.objects[0]);
          setProdReps(response.data.body.objects[1]);
          setProdReps(response.data.body.objects[1], async () => {
            //setProdRepid(response.data.body.objects[1][0]);
            setProdRepid(prodReps[0].productRepid);
          });

          setAmount(response.data.body.objects[0].unitPrice * qty);
        }
      );
    } catch (error) {
      console.log("error ", error);
    }
  };

  React.useEffect(() => {
    loadProductCategories();
  }, []);

  const handleOpen = () => {
    setOpen(true);

    onFirstNameChange({ data: "test 2" });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeProductCat = () => {};

  const handleLoginClick = () => {};

  const RenderProduct = () => {
    return (
      <div>
        {prod ? (
          <div>
            <img
              src={prod.defaultFileImage.url}
              alt={prod.name}
              style={{ width: "200px" }}
            />
          </div>
        ) : (
          <p>N/A</p>
        )}
      </div>
    );
  };

  const RenderProductForm = () => {
    return (
      <div>
        {prod ? (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                select
                label="Size"
                value={prodRepid}
                onChange={(event) => {
                  setProdRepid(event.target.value);
                }}
                fullWidth
              >
                {prodReps.map((option) => (
                  <MenuItem
                    key={option.productRepid}
                    value={option.productRepid}
                    onClick={(event) => {
                      setCatDetail(event.target.textContent);
                    }}
                  >
                    {option.prodRepAttrValue.category.detail} (avail:{" "}
                    {option.numAvail})
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <TextField
                margin="dense"
                label="Qty"
                type="number"
                value={qty}
                onChange={(event) => {
                  setQty(event.target.value);
                }}
                onBlur={(event) => {
                  setAmount(prod.unitPrice * qty);
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                margin="dense"
                label="Unit Price"
                type="number"
                value={prod.unitPrice}
                fullWidth
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                margin="dense"
                label={"Amount"}
                type="number"
                value={amount}
                onChange={(event) => {
                  setAmount(event.target.value);
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        ) : (
          <p>N/A</p>
        )}
      </div>
    );
  };

  return (
    <div>
      <IconButton color="primary" onClick={handleOpen}>
        <AddShoppingCartIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">New Sale Item</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add a new sale item to the order.
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                select
                label="Product Category"
                value={prodCat}
                onChange={(event) => {
                  setProdCat(event.target.value);
                }}
                fullWidth
              >
                {prodCats.map((option) => (
                  <MenuItem
                    key={option.categoryCode}
                    value={option.categoryCode}
                  >
                    {option.detail}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    name="checkedB"
                    color="primary"
                    value={includeHidden}
                    onChange={(event) => {
                      console.log("Switch", event.target.checked);
                      setIncludeHidden(event.target.checked);
                    }}
                  />
                }
                label="Include Hidden"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  loadProducts();
                  setProd(null);
                }}
              >
                Find Product
              </Button>
            </Grid>
            <Grid item xs={9}>
              <TextField
                select
                label="Products"
                value={prodid}
                onChange={(event) => {
                  setProdid(event.target.value);
                }}
                fullWidth
              >
                {prods.map((option) => (
                  <MenuItem key={option.productid} value={option.productid}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  getProduct();
                }}
              >
                Choose
              </Button>
            </Grid>
            <Grid item xs={6}>
              <RenderProduct />
            </Grid>
            <Grid item xs={6}>
              <RenderProductForm />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              // onFirstNameChange({ data: "test " + prod });
              onDone({
                product: prod,
                qty: qty,
                productRepid: prodRepid,
                amount: amount,
                productName: catDetail,
              });
              setProd(null);
              setProdRepid(null);
              setOpen(false);
            }}
          >
            Add to order
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
