import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import API from "../services/api";
import Grid from "@material-ui/core/Grid";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  MenuItem,
  Snackbar,
  Switch,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

export default function CommonDialog({ dataToChild, onDone }) {
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [SuccessTxt, setSuccessTxt] = React.useState("Success!");
  const [openError, setOpenError] = React.useState(false);
  const [ErrorTxt, setErrorTxt] = React.useState("Error!");

  React.useEffect(() => {

    console.log("dataToChild", dataToChild);

    if (dataToChild) { // && dataToChild.startsWith("success")) {
    // if (auth && auth !== '' && auth !== 'N/A') {
      if (dataToChild[0] === 'success') {

        setSuccessTxt(dataToChild[1]);
        setOpenSuccess(true);
      } else if (dataToChild[0] === 'error') {
        setErrorTxt(dataToChild[1]);
        setOpenError(true);
      }
    }
    // else if (dataToChild && dataToChild.startsWith("error")) {
    //   setOpenError(true);
    // }

  }, [dataToChild]);

  const showError = (txt) => {
    console.log("in showError", txt);
    setErrorTxt(txt);
    setOpenError(true);
  };

  const showSuccess = (txt) => {
    console.log("in showSuccess", txt);
    setSuccessTxt(txt);
    setOpenSuccess(true);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setErrorTxt("Some Error");
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
    setSuccessTxt("Success!");
  };

  return (
    <div>
      <Snackbar
        open={openError}
        onClose={handleCloseError}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        key={"openError"}
      >
        <Alert onClose={handleCloseError} severity="error">
          {ErrorTxt}
        </Alert>
      </Snackbar>

      <Snackbar
        open={openSuccess}
        onClose={handleCloseSuccess}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        key={"openSuccess"}
      >
        <Alert onClose={handleCloseSuccess} severity="success">
          {SuccessTxt}
        </Alert>
      </Snackbar>
    </div>
  );
}
